<template>
  <div class="development-history-page">
    <time-axis :course="course"></time-axis>
  </div>
</template>

<script>
import timeAxis from "@components/timeAxis";
export default {
  name: "",
  components: {
    timeAxis,
  },
  data() {
    return {
      course: []
    };
  },
  computed: {
    getSubMenu() {
      return this.$store.state.permission.subMenu;
    },
    getCurrentRoute() {
      return this.$store.state.permission.currentRoute;
    },
  },
  watch: {
    getSubMenu: {
      deep: true,
      handler() {
        this.getList();
      },
    },
  },
  methods: {
    getList() {
      if (Object.keys(this.getSubMenu).length == 0) return;
      let menu = this.getSubMenu.childList.filter(
        (item) => item.router === this.getCurrentRoute
      )[0];
      if (menu) {
        this.$post(menu.Interface).then((res) => {
          this.course = res
		  console.log('course',res)
        });
      }
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.development-history-page {
  width: 1200px;
  margin: 0 auto;
}
</style>